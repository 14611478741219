.setting_header {
  display: flex;
  justify-content: space-between;
}

.setting_format {
  display: flex;
  justify-content: space-between;
}

.setting_header_text {
  font-size: 25px;
}

.setting_content{
    background-color: white;
    padding: 15px 15px;
    border-radius: 10px;
}
.setting_text_link{
    padding: 10px 0 0 0;
}
