.login {
  background-color: #dd631b;
  width: 100vw;
  // background-color: #00152A;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.login_img {
  width: 10rem;
  height: 10rem;
  // width: 5rem;
  // height: 5rem;
  object-fit: contain;
  margin: 0 0 50px 0;
  border: 1px solid #fff;
  border-radius: 30px;
  overflow: hidden;
}

.login_img_parent {
  display: flex;
  padding: 3rem 0 2rem 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login_img_parent > div {
  font-size: 2.5rem;
  font-weight: 900;
  color: #1890ff;
}
.login_choose_parent {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
}
.login_choose {
  display: flex;
  width: 10rem;
  justify-content: space-between;
  align-items: center;
}

.login_choose_login {
  margin-right: 3rem;
}

.login_choose > div {
  cursor: pointer;
}
.login_choose > div:hover {
  color: #24a7ec;
}
.login_choose_status_active {
  padding-bottom: 1rem;
  border-bottom: 2px solid #24a7ec;
  font-size: 1.25rem;
  color: #24a7ec;
  height: 2.5rem;
  cursor: pointer;
}
.login_choose_status {
  /* padding-bottom: 1rem;
    border-bottom: 2px solid #24a7ec; */
  font-size: 1.25rem;
  height: 2.5rem;
  cursor: pointer;
  color: white;
}
.login_bottom {
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin: auto !important;
  padding: 20px 0 0 0!important;
  border-radius: 10px;
}
.login_bottom_left > div {
  color: #24a7ec;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
}
.login_bottom_left_checkbox {
  color: black !important;
  font-size: 1.5rem;
}
.login_bottom_left_button_parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_bottom_email_text, .login_bottom_password_text {
  font-weight: 500;
  width: 70%;
  // margin-right: 300px;
  
}
@media only screen and (min-width: 320px) {
  .login_bottom_email {
    width: 18.5rem;
  }
  .login_bottom_password {
    width: 18.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 18.5rem;
  }
  .login_bottom_left_button {
    width: 18.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 300px) {
  .login_bottom_email {
    width: 16.5rem;
  }
  .login_bottom_password {
    width: 16.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 16.5rem;
  }
  .login_bottom_left_button {
    width: 16.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}

@media only screen and (min-width: 380px) {
  .login_bottom_email {
    width: 20.5rem;
  }
  .login_bottom_password {
    width: 20.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 20.5rem;
  }
  .login_bottom_left_button {
    width: 20.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 576px) {
  .login_bottom_email {
    width: 22.5rem;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .login_bottom_email {
    width: 22.5rem;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 992px) {
  .login_bottom_email {
    width: 22.5rem;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .login_bottom_email {
    width: 22.5rem;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 1600px) {
  .login_bottom_email {
    width: 22.5rem;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-size: 1rem;
  /* font-weight: 600; */
  color: white;
  margin-bottom: 1.5rem;
  position: fixed;
  bottom: 0;
  /* height: 50%; */
}
.login_bottom_left_forgot_password {
  margin-top: 0.25rem;
}

.loading {
  display: flex;
  visibility: visible;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
}
.loading_active {
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
}
.spinner-3 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #25b09b);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
