.share-create-header-icon{
  display: block;
  width: 80%;
}

.share-create-header {
  display: flex;
  justify-content: space-between;
}

.share-create-header-left {
  display: flex;
}

a.jodit-status-bar-link {
  display: none!important;
}