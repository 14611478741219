.share_header {
  display: flex;
  justify-content: space-between;
}

.share_format {
  display: flex;
  justify-content: space-between;
}

.share_header_text {
  font-size: 25px;
}

.ant-popover-buttons button {
  border-radius: 5px!important;
}