.employee_manager {
  display: flex;
  justify-content: center;
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  align-items: center;
  flex-direction: column;
}
.employee_manager_top {
  display: flex;
  // border-radius: 0.5rem;
  // background-color: white;

  // margin-bottom: 1rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.status_active {
  color: #169f00;
}
.status {
  color: #f20000;
}
.employee_manager_top_select_parent_col {
  width: 100%;
  margin-bottom: 1rem;
}
.employee_manager_top_select_parent_col_right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-bottom: 1rem;
}
.employee_manager_top_select_parent {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
.employee_manager_top_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.employee_manager_top_center {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.employee_manager_top_center_item_top {
  color: black;
  font-size: 1rem;
  font-weight: 600;
}
.employee_manager_top_center_item_price {
  color: black;
  font-size: 1.25rem;
  font-weight: 600;
}
.employee_manager_top_center_item {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 1rem;
  background-color: #5363e0;
  align-items: center;
  flex-direction: column;
  border-radius: 0.25rem;
  line-height: 2rem;
}
.employee_manager_top_center_col {
  // border: 1px solid grey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  cursor: pointer;
  display: flex;
  // border-radius: 1rem;
  // margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
}
.employee_manager_top_center_col:hover {
  transform: scale(1.05);
}
.employee_manager_top_center_item > div {
  // color: black;
  // font-weight: 600;
  // font-size: 1rem;

  color: white;
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 1.25rem;
  border: 0 solid transparent;
  overflow: hidden;
}
.employee_manager_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  // padding: 1rem 1rem 0 1rem;
  background-color: white;
  border-radius: 0.5rem;
}
.employee_manager_bottom_right_select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.employee_manager_bottom_right_select_child {
  width: 100%;
}
.employee_manager_bottom_search {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.employee_manager_bottom_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.employee_manager_bottom_col {
  width: 100%;
  margin-bottom: 1rem;
}
.employee_manager_bottom_col_right {
  width: 100%;
}
.employee_manager_bottom_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.employee_manager_bottom_right_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}
.employee_manager_bottom_right_bottom_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.employee_manager_bottom_table {
  max-width: 100%;
  overflow: auto;
  width: 100%;
  margin-top: 1rem;
  border: 1px solid rgb(231, 224, 224);
}
.employee_manager_bottom_table_child {
  width: 100%;
  background-color: white;
}
.change {
  cursor: pointer;
  color: #faad14;
}
.employee_manager_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.employee_manager_search_row_col_search_child {
  width: 100%;
}
.employee_manager_search_row_col_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.employee_manager_search_row_col_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.employee_manager_search_row_col {
  width: 100%;
  // margin-bottom: 1rem;
}
.employee_manager_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.employee_manager_search_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.employee_manager_search_row_col_button_right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
}
.employee_manager_button_main {
  width: 10rem;
  margin-bottom: 1rem;
}
.employee_manager_button_left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.employee_manager_button_right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work_date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.5rem;
  flex-direction: column;
}
.work_date_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.work_date_item_title {
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.work_date_item_title_span {
  font-weight: 600;
  color: #196ce8;
}
.employee_manager_title {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 1rem;
}
.employee_add {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem 1rem 1rem;
  justify-content: flex-start;
  align-items: center;
}
.employee_add_parent {
  background-color: white;
  margin: 1rem;
  display: flex;
  padding: 1rem 1rem 1rem 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.employee_add_title {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
  color: black;
}
.employee_add_parent_row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
}
.employee_add_parent_row_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.employee_add_parent_col {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.employee_add_parent_col_button_two {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
}
.employee_add_parent_col_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.employee_add_parent_col_row_child {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.date {
  width: 100%;
}
.upload_icon {
  width: 100%;
}
.pos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.pos_top {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.employee_add_parent_row_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.employee_add_parent_col_bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.employee_add_parent_col_row_child_bottom {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
}
.pos_top_title {
  width: 50%;
  font-size: 0.75rem;
}
.employee_add_parent_col_row_child_account {
  margin-top: 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: black;
}
.employee_add_parent_col_row_child_input {
  margin-bottom: 1rem;
}
.employee_add_parent_col_row_child_name {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: black;
}
.pos_top_input {
  width: 100%;
}
.employee_edit_button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.employee_edit_button_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.employee_edit_button_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.employee_add_parent_col_save {
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.supplier_information {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem 1rem 0 1rem;
  background-color: white;
  justify-content: center;
  align-items: center;
}
.supplier_information_title {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(233, 227, 227);
  cursor: pointer;
  // margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.supplier_information_title_right {
  font-weight: 600;
  font-size: 1rem;
  margin-left: 0.5rem;
  color: black;
}
.supplier_information_content_child_left {
  display: flex;
  // margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
}
.supplier_information_content_child_right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}
.supplier_information_content_main {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.supplier_information_content_parent {
  background-color: white;
  padding: 1.5rem 2.5rem 0 2.5rem;
  width: 100%;
}
.supplier_information_content_child_left_title {
  width: 100%;
  color: black;
  font-weight: 600;
  margin-right: 1rem;
}
@media only screen and (max-width: 300px) {
  .supplier_information_content_child {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .supplier_information_content {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 380px) {
  .supplier_information_content_child {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .supplier_information_content {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .supplier_information_content_child {
    display: flex;
    margin-bottom: 1rem;

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .supplier_information_content {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .supplier_information_content_child {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .supplier_information_content {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .supplier_information_content_child {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .supplier_information_content {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .supplier_information_content_child {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .supplier_information_content {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 1600px) {
  .supplier_information_content_child {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .supplier_information_content {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }
}
.input_custom {
  width: 100%;
  // padding: 0.25rem 0.5rem;
  // border: 1px solid rgb(223, 208, 208);
}
