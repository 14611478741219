// #components-layout-demo-custom-trigger .trigger {
//   padding: 0 24px;
//   font-size: 18px;
//   line-height: 64px;
//   cursor: pointer;
//   transition: color 0.3s;
// }

// #components-layout-demo-custom-trigger .trigger:hover {
//   color: #1890ff;
// }

// #components-layout-demo-custom-trigger .logo {
//   height: 32px;
//   margin: 16px;
//   background: rgba(255, 255, 255, 0.3);
// }

.header_custom_col_right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.header_custom_col_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.header_custom_col {
  width: 100%;
  margin-bottom: 1rem;
}
.logo_left {
  // margin-left: 1rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.logo_left_icon {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 10rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
  height: 5rem;
  color: #fff;
  transition: all 0.5s;
}
.logo_left_icon_small {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1.25rem;
  height: 40px;
  color: #fff;
  transition: all 0.2s;
}
.bell_item {
  width: 18.5rem;
  padding: 0 0rem 0 0;
  margin-top: 1rem;
}
.bell_date_username {
  color: black;
  // background-color: red;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-right: 1rem;
  // font-size: 1rem;
}
.logo_left_parent_active {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.logo_left_parent {
  display: flex;
  font-size: 1.25rem;
  font-weight: 900;
  color: #1890ff;
  margin: 0.75rem 0.5rem 1rem 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.information_left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.level_left {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: white;
  margin-bottom: 0rem;
  width: 100%;
  transition: all 1s;
  opacity: 1;
  text-align: center;
}
.level_left_active {
  visibility: hidden;
  display: none;
  transition: all 1s;
}
.gold_left {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: white;
  font-weight: 750;
  margin-bottom: 15px;
  width: 100%;
}
.gold_left_active {
  visibility: hidden;
  opacity: 0;
  display: none;
}
.cart__total {
  font-size: 0.75rem;
  background-color: red;
  color: white;
  position: absolute;
  top: -13px;
  right: 7.5px;
  padding: 0.1rem 0.3rem;
  border-radius: 100%;
}
.header_custom_right_question {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  /* width: 100%; */
}
.header_custom_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.header_custom_toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.header_custom_right_users {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
}

.header_custom_right_users_img_name {
  display: flex;
  margin-right: 1rem;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.header_custom_right_users_img {
  width: 2rem;
  // margin-right: 1rem;
  height: 2rem;
  object-fit: contain;
}
.header_custom_right_icon {
  width: 2rem;
  height: 2rem;
}
.header_custom_right_users_child {
  display: flex;
  // min-width: 8rem;
  justify-content: center;
  align-items: flex-start;
  height: 4rem;
  flex-direction: column;
  padding: 0 25px 0 15px;
}
.header_custom_right_users_child_username {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  // height: 43px;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: 600;
  color: black;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  display: inline-block; /* <- Prevent <tr> in a display css */
  overflow: hidden;
  text-overflow: ellipsis;
}
.header_custom_right_users_child_username_money {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  // height: 43px;
  margin: 0 auto;
  padding-top: 0.25rem;
  font-size: 1rem;
  // font-weight: 600;
  // color: black;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  display: inline-block; /* <- Prevent <tr> in a display css */
  overflow: hidden;
  text-overflow: ellipsis;
}
.active_version {
  display: flex !important;
  align-items: flex-end;
  height: 600px;
}
.unactive_version {
  display: none !important;
}
@media only screen and (max-width: 320px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (max-width: 300px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width: 380px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (max-width: 1600px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (max-width: 2000px) {
  .header_custom {
    display: flex;
    padding: 0 1rem;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
.popoper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 9.5rem;
  flex-direction: column;
}

.left_menu {
  // min-height: 100vh;
  // min-width: 100vw;
  // background-color: #001529;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.grid {
  display: grid;
  grid-template-columns: 15vw 85vw;
  width: 100vw;
  // grid-column-gap: 1.5rem;
}
.grid_right {
  width: 85vw;
  z-index: 999999;
}
.grid_left {
  width: 15vw;
}
.site-layout-background {
  min-height: 100vh;
  background-color: #dd631b !important;
}
.site-layout-background-logout {
  color: #fff !important;
}
.site-layout {
  width: 100%;
}
.popoper_parent {
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgb(216, 201, 201);
  padding-bottom: 1rem;
}
.popoper_parent > div {
  margin-right: 0.5rem;
}
.popoper_parent_bottom > div {
  margin-right: 0.5rem;
}
.popoper_parent_bottom {
  display: flex;
  margin-top: 1rem;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.bell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // height: 13.5rem;
  flex-direction: column;
  width: 100%;
}
.bell_date {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.bell_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(224, 211, 211);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 90px;
}
.news_show {
  max-height: 13.5rem;
  overflow: auto;
}
.bell_bottom {
  margin-top: 0.75rem;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.bell_bottom > div {
  font-size: 1rem;
  font-weight: 600;
  color: #1890ff;
}
.bell_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // margin-bottom: 1rem;
  color: black;
  // font-size: 1rem;
  font-weight: 600;
}
.bell_bottom > div {
  margin-right: 0.5rem;
}
