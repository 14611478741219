.device-format-text {
  font-size: 25px;
}

.device-format-table {
  background-color: #F0F2FA;
  border-radius: 5px;
  overflow: hidden;
}

.ant-pagination-item-active {
  color: #fff!important;
  background-color: #DD631B!important;
}

.ant-table table {
  border-radius: 5px 5px 5px 5px !important;
}